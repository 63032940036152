.header-history {
    background: #d5d5d5;
    border-bottom: 1px solid #efefef;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #818181;
}
.header-history > div {
    padding: 5px;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
}
.content-history:nth-child(even) {
    background: #fff;
}
.content-history {
    background: #f5f5f5;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #818181; 
}
.content-history > div {
    padding: 5px;
    font-size: 12px;
    text-align: center;
}